import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2249B3",
    },
    secondary: {
      light: "#ffecf1",
      main: "#FCAACF",
    },
    thirdly: {
      main: "#6d38cf",
    },
    fourthly: {
      main: "#eb0f71",
    },
    error: {
      main: "#f00",
    },
    background: {
      default: "#ffecf1",
    },
  },
});

export default theme;
